#product {
  margin-top: 0vh;
  max-width: 100vw;
  height: 90vh;
  /* background-color: white; */
}
#product h2 {
  margin-top: 75px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 2em;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.4px;
  text-align: left;
  color: #161D25;
  margin-bottom: 10px;
}
#product .left {
  height: 90vh;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size:cover;
  display: block;
}
.inci {
  padding: 5vh;
  width: 90% !important;
  position: absolute;
  top: 7vh;
  margin: auto;
  left: 0;
  right: 0;
}  
@media only screen and (max-width: 600px) {
  .inci {
    padding-bottom: 3vh;
    width: 90% !important;
    font-size: 2vh !important;
    position: absolute;
    top: 7vh;
    margin: auto;
    left: 0;
    right: 0;
    padding: 0;
    padding-bottom: 3vh;
  }
  .mpLinks {
    padding-bottom: 10vh !important;
  }
  #product {
    margin-top: 0;
    position: relative;
    max-width: 100% !important;
    height: 100%;
  }
  #product .left {
    height: auto !important;
  }
  #product .left div {
    height: auto;
    padding-bottom: 50px;
  }
  #product .right { 
    height: auto;
    padding-bottom: 10px;
    width: 100% !important;
  }
  #product .right img {
    height: 100% !important;
    width: 100%;
    object-fit:cover;
  }
  #product .left div {
    max-width: 90%;
    margin: auto;
  }
  #product .left p {
    max-width: 100%;
    font-size: 2vh !important;
  }
  #product h2 {
    margin-top: 5vh !important;
    font-size: 3vh !important;
  }
  .mpLinks a:nth-of-type(3) {
    margin-top: 3vh !important;
  }
  .mpLinks a {
    height: 3.5vh !important;
  }
}
.mpLinks a {
  font-family: 'Roboto';
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: block;
  height: 3vh;
  margin-top: 2vh;
  color: #FFFFFF;
  text-align: center;
  padding-top: 0.7vh;
  border-radius: 5px;
  width: 123px;
  height: 21px;
}
.mpLinks {
  display: flex !important;
  justify-content: space-around;
  align-content: space-around;
  align-items: baseline;
  flex-wrap: wrap;
  margin: auto !important;
}
#product p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #161D25;
  text-align: left;
  width: 600px;
  line-height: 1.4em;
  margin-top: 1em;
}
#product .left {
  height: 90vh;
}
#product .left div {
  margin: auto;
  width: 600px;
  display: block;
}
.inciLink {
  text-decoration: underline;
  cursor: pointer;;
  font-family: 'Roboto';
  font-style: normal;
  font-size: 0.8em;
  line-height: 100%;
  text-align: left;
}
#product .right {
  width: 40%;
}
#product .right img {
  height: 90vh;
  width: 100%;
  object-fit: cover;
}
.partHeadline {
  margin-top: 2em !important;
}
.volume {
  margin-top: 0px !important;
}