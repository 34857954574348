@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  color: black;
  background-color: white;
  /* max-width: 100vw !important; */
  /* min-width: 100vw !important; */
}
@media only screen and (min-width: 600px) {
  h1 {
    font-size: 100px;
  }
}
h2 {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 2em;
  line-height: 38px;
  color: black;
}
h3 {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 38px;
  padding-left: 20px;
}
p {
  font-size: 1em !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 1em;
  line-height: 110%;
  color: #161D25;
  text-align: left;
  line-height: 1.2em;
}
@media only screen and (max-width: 600px) {
  #back {
    color: black !important;
    left: 15px !important;
    top: 0px !important;
    font-family: Roboto;
    font-weight: 300 !important;
    font-size: 1em !important;
  }
  p {
    width: 300px !important;
    font-size: 0.8em !important;
  }
  button {
    width: 300px !important;
  }
}
img {
  width: 100%;
}
video {
  width: 100%;
}
.container {
  position: relative;
  height: 100%;
}
.more {
  font-family: Helvetica Neue;
  font-weight: bold;
  font-size: 24px;
  color: black;
  position: absolute;
  /* padding: 10px; */
  bottom: -10px;
  right: 0px;
}
#back {
  text-decoration: none;
  color: black;
  position: absolute;
  left: 80px;
  top: 0px;
  font-family: Roboto;
  font-weight: bold;
  font-size: 24px;
}
@media only screen and (min-width: 600px) {
  .left {
    float: left;
    height: 100%;
    width: 50%;
  }
  .right {
    float: left;
    width: 50%;
  }
}
.underline {
  text-decoration-color: black;
  text-decoration-thickness: 5px;
}
.paddingTop {
  padding-top: 35px;
}
button {
  font-family: Roboto;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 1em;
  color: black;
}
button:hover {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  /* transform: translateY(-1px); */
  cursor: pointer;
}