#products {
    max-width: 50vw;
    margin: 0 auto;
    min-height: 100%;
    padding-bottom: 5em;
    padding-top: 40vh;
  }
#products h2 {
  text-align: center;
  margin-top: 75px;
  margin-bottom: 50px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 2em;
  line-height: 107%;
  letter-spacing: -0.4px;
  text-transform: uppercase;
  color: #161D25;
  display: block;
}
#products h3 {
  font-family: Roboto;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1em;
  padding: 0;
  width: 100%;
  line-height: 100%;
  margin-top: 30px;
  margin-bottom: 0px;
}
.imageBg > img{
  background: -webkit-linear-gradient(117.01deg, #A2CEF4 0%, #E1EFFF 100%);
}
#products button {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border: 1px solid #000000;
  background-color: white;
  margin-top: 10px;
}
#products a {
  text-decoration: none;
  color: #161D25;;
}
#products p {
  width: 100% !important;
}
@media only screen and (max-width: 600px) {
  #products h2 {
    display: none;
  }
  #products {
    position: absolute;
    max-width: 95vw;
    left: 0;
    right: 0;
    top: 32vh;
    min-height: auto;
    padding-top: 0;
  }
  #products > div {
    width: 100%;
    margin: auto;
    }
}