.profile{
  /* Group 7 */
  position: absolute;
  width: 100vw;
  /* height: 50%; */
  left: 0px;
  top: 0px;/* Rectangle 4 */
  background: linear-gradient(117.01deg, #A2CEF4 0%, #E1EFFF 100%);
}
.profile .fromthemoon {
  margin-top: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  text-transform: lowercase;
  color: #FFFFFF;
  z-index: 1;
}
.profile .elipse {
  position: absolute;
  width: 5vw;
  height: 5vw;
  right: 2vw;
  top: 18px;
  background: #FFFFFF;
  border-radius: 50%;
}
.menu {
  position: absolute;
  top: 55vh;
  left: 5vw;
}
.menuLink {
  /* Heading 3 ⏵ Living Cleansing Balm ™ */
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 2.6vh;
line-height: 6.5vh;
display: flex;

color: #000000;
}
.telegram {
  /* Rectangle 5 */
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  background: #F9F9F9;
  border-top: 1px solid #EBEBEB;
  height: 80px;
  left: 0px;
  bottom: 0px;
}
.telegram a {
  position: absolute;
  height: 40px;
  bottom: 10px;
  left: 0;
  right: 0;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 2vh;
  line-height: 14px;

  text-align: center;

  color: #000000;
}
.ingredients {
  left: 0px !important;
}
.telegram .logo {
  /* Logo 1 */
  position: absolute;
  bottom: 25px;
  height: 36px;
  left: -10vw;
}

#qr a {
  text-decoration: none;
}
.girl {
  position: absolute;
  width: 10vw;
  bottom: 0;
}

@media (max-width: 700px) {
  .telegram {
    width: 100%;
    height: 80px;
    left: 0px;
    bottom: 0px;
  }
  .profile .elipse {
    width: 15vw;
    height: 15vw;
    right: 5vw;
  }
  .menu {
    top: 53%;
    left: 10vw;
  }
  .telegram a {
    height: 40px;
    bottom: 10px;
    left: 13vw;
  }
  .telegram .logo {
    /* Logo 1 */
    bottom: 25px;
    left: -37%;
  }
  .girl {
    width: 40vw;
  }
}

@media (prefers-color-scheme: dark) {
  .profile{
    background: linear-gradient(117.01deg, #082239 0%, #6C9CD2 100%);
  }
  body {
    background: #1F1F1F;
  }
  .telegram {
    background: #171717;
    border-top: 1px solid #282828;
  }
  h1, h2, h3, p, .telegram a, .menuLink {
    color: #F9F9F9 !important;
  }
}