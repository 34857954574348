#header {
  margin-top: 60px;
  min-width: 100vw;
  max-width: 100vw !important;
  height: 90vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size:cover;
  position: relative;
  display: block;
}
#header > div {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 150px;
  }
  #header button {
    box-sizing: border-box;
    height: 40px;
    width: 300px;
    border: 1px solid #000000;
    background-color: transparent;
  }
  @media only screen and (max-width: 600px) {
    #header {
      background-position: center right;
    }
    h1 {
      font-size: 2em !important;
      line-height: 1em;
    }
    #header > div {
      max-width: 90%;
    }
    .control-arrow {
      width: 66px !important;
      display: none;
    }
  }
#header h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 2.5em;
    line-height: 1em;
    display: flex;
    align-items: center;
    letter-spacing: -0.4px;
    text-transform: uppercase;
    color: #161D25;
    text-align: left;
    width: 300px;
  }
#header p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 1em;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #161D25;
    display: block;
    width: 300px;
}