#brand {
    width: 100%;
    /* height: 100vh; */
    background: linear-gradient(180deg, #E0EEFF 0%, #E0EEFF 17.10%, #DAEBFD 52.60%, #D3E8FC 73.80%, #CBE4FA 100%);
    /* background-color: #CBE4FA; */
    /* background-color: #C2DCFF; */
    margin: auto;
    position: absolute;
    top: 30vh;
    background: none;
  }
#brand .right {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size:cover;
  display: block;
}  
#brand .left button {
    margin-top: 20px;
    box-sizing: border-box;
    height: 40px;
    width: 240px;
    border: 1px solid #000000;
    background-color: #C2DCFF;
    color: black;
  }
  .georgia {
    font-family: Georgia, 'Times New Roman', Times, serif !important;
    font-style: italic !important;
  }
@media only screen and (max-width: 600px) {
  #brand {
    height: 10vh;
    position: absolute;
    top: 30vh;
    background: none;
  }
  #brand .left {
    /* background-image: url('./bg_1.jpg'); */
    background-repeat: no-repeat;
    background-position: left 150px bottom 0px;
    background-size:contain;
  }
  #brand .left div {
    width: 90% !important;
  }
  #brand p {
    width: 90% !important;
    font-size: 2.2vh !important;
  }
  #brand h2 {
    display: none !important;
    margin-top: 5vh !important;
  }
  #brand .right {
    display: none;
  } 
}
  #brand h2 {
    margin-top: 200px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    line-height: 107%;
    display: flex;
    align-items: center;
    letter-spacing: -0.4px;
    text-align: left;
    text-transform: uppercase;
    color: #161D25;
  }
#brand .left {
  height: 100%;
  width: 100%;
}
#brand .left div {
  position: absolute;
  width: 90vw;  
  margin-top: 5vh;
  left: 2vw;
}
#brand p {
  width: 500px;
  font-size: 2.5vh !important;
}