#footer {
    max-width: 100vw;
    height: 140px;
    background-color: #A7B7CD;
    position: relative;
    /* margin-top: 100px; */
  }
#footerLogo {
  width: 100px;
  position: absolute;
  top: 20px;
  left: 200px;
}
#footer a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 0.8em !important;
    line-height: 150%;
    color: white;
    text-align: left;
    text-decoration: none;
}
#footer ul {
  list-style-type: none;
  display: flex;
  position: absolute;
  right: 10px;
  flex-direction: column;
}
#footer li {
  margin-top: 10px;
  text-align: left;
  width: 150px;
}
#footer .nav-elements {
  width: 90%;
  margin: auto;
}
#footer p {
  font-family: Roboto;
  font-size: 0.8em !important;
  font-weight: 300;
  color: white;
}
.email {
  position: absolute;
  left: 200px;
  margin-left: auto; 
  margin-right: auto;
  text-align: left; 
  width: auto; 
  top: 85px;
}
.copyright {
  position: absolute;
  bottom: 5px;
  text-align: center;
  width: 100% !important;
  margin-bottom: 0 !important;
}
@media only screen and (max-width: 600px) {
  .copyright {
    bottom: 10px;
  }
  #footer {
    height: 150px;
  }
  #footer li {
    width: 80px !important;
  }
  #footerLogo {
    width: 100px;
    position: absolute;
    top: 30px;
    left: 30px;
  }
  .email{
    left: 30px;
  }
  #footer ul {
    margin-top: 20px;
  }
  #footer .nav-elements {
    width: 60%;
    font-size: 0.8em !important;
    margin: auto;
  }
}
