#stores {
    max-width: 60vw;
    margin: 0 auto;
    min-height: 100%;
    padding-top: 0rem;
    padding-bottom: 7rem;
  }
#stores > div {
  width: 800px;
  margin: auto;
  }
  #stores h2 {
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 2em;
    line-height: 107%;
    letter-spacing: -0.4px;
    text-transform: uppercase;
    color: #161D25;
    display: block;
    margin-bottom: 10%
  }
.product {
  width: 27%;
  position: relative;
  height: 100%;
}
#stores button {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border: 1px solid #000000;
  background-color: white;
  margin-top: 10px;
}
@media only screen and (max-width: 600px) {
  #stores {
    max-width: 100vw;
  }
  #stores > div {
    width: 100%;
    }
#stores .product {
  width: 50%;
  position: relative;
  margin: auto;
  height: 100%;
  margin-bottom: 70px;
  } 
}